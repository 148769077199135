import { createPersistedReducer, formatBoard, formatGame } from './utils';

const receiveBoards = (boardState, { payload }) => ({
  ...boardState,
  boards: payload.map(formatBoard),
});

const receiveBoard = (boardState, { payload }) => {
  const { boards } = boardState;
  const { id } = payload;
  const filteredBoards = boards.filter(({ id: boardId }) => id !== boardId);

  return {
    ...boardState,
    boards: [
      ...filteredBoards,
      formatBoard(payload),
    ],
  };
};

const removeBoard = (boardState, { payload: id }) => {
  const { boards: prevBoards } = boardState;
  const boards = prevBoards.filter((board) => board.id !== id);
  return { ...boardState, boards };
};

const clearBoards = () => ({ boards: [] });

const receiveActiveBoard = (boardState, { payload }) => {
  const { game, ...restOfPayload } = payload;
  const active = formatBoard(restOfPayload);
  return { ...boardState, active: { ...active, game: formatGame(game) } };
};

const boardReducer = createPersistedReducer({ boards: [] }, {
  CLEAR_BOARDS: clearBoards,
  RECEIVE_ACTIVE_BOARD: receiveActiveBoard,
  RECEIVE_BOARD: receiveBoard,
  RECEIVE_BOARDS: receiveBoards,
  REMOVE_BOARD: removeBoard,
}, { active: null, boards: [] });

export default boardReducer;
