import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BoardForm from 'components/BoardForm';
import { updateBoardAction } from 'js/actions/boardActions';

const UpdateBoardContainer = (props) => {
  const {
    board,
    gameId,
    updateBoard,
    error,
    isLoading,
    isVisible,
    onClose,
  } = props;

  const { id } = board || {};

  const handleSave = (payload) => {
    updateBoard({ id, gameId, ...payload });
  };

  return id ? (
    <BoardForm
      board={board}
      error={error}
      header="Edit Board Settings"
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSave={handleSave}
    />
  ) : null;
};

UpdateBoardContainer.propTypes = {
  board: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  gameId: PropTypes.string.isRequired,
  updateBoard: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { updateBoardError },
    loading: { isUpdateBoardLoading },
  } = state;

  return {
    error: updateBoardError || {},
    isLoading: isUpdateBoardLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateBoard: (payload) => dispatch(updateBoardAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateBoardContainer);
