import React from 'react';
import PropTypes from 'prop-types';

const ExampleTwoSvg = ({ color }) => (
  <svg width="231" height="43" viewBox="0 0 231 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42.5676" height="42.5676" rx="21.2838" fill="#404342" />
    <rect x="0.752514" y="0.752514" width="41.0625" height="41.0625" rx="20.5313" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M14.0267 30L7.9676 12.0282H9.91883L15.5928 28.9474H14.6172L20.4708 12.0282H22.2167L27.9933 28.9474H27.0691L32.7944 12.0282H34.6172L28.5582 30H26.5556L21.0357 13.9538H21.5492L16.0036 30H14.0267Z" fill="white" />
    <rect x="47.1084" width="42.5676" height="42.5676" rx="21.2838" fill={color} />
    <rect x="47.8609" y="0.752514" width="41.0625" height="41.0625" rx="20.5313" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M59.242 30L67.4577 12.0282H69.3319L77.5476 30H75.545L67.9968 13.1322H68.7671L61.2189 30H59.242ZM62.4769 25.199L63.0418 23.6585H73.4911L74.0559 25.199H62.4769Z" fill="white" />
    <rect x="94.2158" width="42.5676" height="42.5676" rx="21.2838" fill="#404342" />
    <rect x="94.9683" y="0.752514" width="41.0625" height="41.0625" rx="20.5313" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M114.547 30V13.6713H108.231V12.0282H122.763V13.6713H116.447V30H114.547Z" fill="white" />
    <rect x="141.324" width="42.5676" height="42.5676" rx="21.2838" fill="#404342" />
    <rect x="142.077" y="0.752514" width="41.0625" height="41.0625" rx="20.5313" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M164.113 30.154C162.761 30.154 161.512 29.9315 160.365 29.4865C159.218 29.0244 158.225 28.3825 157.387 27.561C156.548 26.7394 155.889 25.7723 155.41 24.6598C154.948 23.5473 154.716 22.332 154.716 21.0141C154.716 19.6962 154.948 18.4809 155.41 17.3684C155.889 16.2559 156.548 15.2888 157.387 14.4672C158.242 13.6457 159.244 13.0124 160.39 12.5674C161.537 12.1052 162.787 11.8742 164.139 11.8742C165.44 11.8742 166.663 12.0967 167.81 12.5417C168.957 12.9696 169.924 13.62 170.711 14.4929L169.505 15.6996C168.769 14.9465 167.956 14.4073 167.066 14.0821C166.176 13.7398 165.217 13.5686 164.19 13.5686C163.112 13.5686 162.111 13.7569 161.186 14.1335C160.262 14.4929 159.458 15.0149 158.773 15.6996C158.088 16.3671 157.549 17.1544 157.155 18.0616C156.779 18.9516 156.591 19.9358 156.591 21.0141C156.591 22.0924 156.779 23.0851 157.155 23.9923C157.549 24.8823 158.088 25.6697 158.773 26.3543C159.458 27.0218 160.262 27.5439 161.186 27.9204C162.111 28.2798 163.112 28.4596 164.19 28.4596C165.217 28.4596 166.176 28.2884 167.066 27.9461C167.956 27.6038 168.769 27.056 169.505 26.3029L170.711 27.5096C169.924 28.3825 168.957 29.0415 167.81 29.4865C166.663 29.9315 165.431 30.154 164.113 30.154Z" fill="white" />
    <rect x="188.433" width="42.5676" height="42.5676" rx="21.2838" fill="#404342" />
    <rect x="189.185" y="0.752514" width="41.0625" height="41.0625" rx="20.5313" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M215.346 12.0282H217.22V30H215.346V12.0282ZM204.101 30H202.201V12.0282H204.101V30ZM215.525 21.7073H203.895V20.0385H215.525V21.7073Z" fill="white" />
  </svg>
);

ExampleTwoSvg.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ExampleTwoSvg;
