import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BoardForm from 'components/BoardForm';
import { createBoardAction } from 'js/actions/boardActions';

const CreateBoardContainer = (props) => {
  const {
    createBoard,
    error,
    gameId,
    isLoading,
    isVisible,
    onClose,
  } = props;

  const handleSave = (payload) => createBoard({ gameId, ...payload });

  return (
    <BoardForm
      board={{}}
      error={error}
      header="New Board Settings"
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

CreateBoardContainer.propTypes = {
  createBoard: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  gameId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { createBoardError },
    loading: { isCreateBoardLoading },
  } = state;

  return {
    error: createBoardError || {},
    isLoading: isCreateBoardLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createBoard: (payload) => dispatch(createBoardAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateBoardContainer);
