import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Game from 'components/Game';
import {
  clearBoardsAction,
  createBoardAction,
  deleteBoardAction,
  getBoardsAction,
} from 'js/actions/boardActions';

const GameContainer = (props) => {
  const {
    boards,
    clearBoards,
    createBoard,
    deleteBoard,
    games,
    getBoards,
    isLoading,
  } = props;

  const { gameId } = useParams();

  useEffect(() => {
    clearBoards();
    getBoards(gameId);
  }, []);

  const game = games.find(({ id }) => id === gameId);

  const handleDuplicate = (payload) => createBoard({ gameId, ...payload });
  const handleRemove = ({ id }) => deleteBoard({ gameId, id });

  return (
    <Game
      boards={boards}
      game={game}
      isLoading={isLoading}
      onDuplicate={handleDuplicate}
      onRemove={handleRemove}
    />
  );
};

GameContainer.propTypes = {
  boards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clearBoards: PropTypes.func.isRequired,
  createBoard: PropTypes.func.isRequired,
  deleteBoard: PropTypes.func.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getBoards: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    boards: { boards },
    games: { games },
    loading: { isCreateBoardLoading, isGetBoardsLoading },
  } = state;

  return {
    boards,
    games,
    isLoading: isCreateBoardLoading || isGetBoardsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearBoards: (payload) => dispatch(clearBoardsAction(payload)),
  createBoard: (payload) => dispatch(createBoardAction(payload)),
  getBoards: (payload) => dispatch(getBoardsAction(payload)),
  deleteBoard: (payload) => dispatch(deleteBoardAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GameContainer);
