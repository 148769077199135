import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GameForm from 'components/GameForm';
import { createGameAction } from 'js/actions/gameActions';

const CreateGameContainer = (props) => {
  const {
    createGame,
    error,
    isLoading,
    isVisible,
    onClose,
  } = props;

  return (
    <GameForm
      game={{}}
      error={error}
      header="New Game Settings"
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSave={createGame}
    />
  );
};

CreateGameContainer.propTypes = {
  createGame: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { createGameError },
    loading: { isCreateGameLoading },
  } = state;

  return {
    error: createGameError || {},
    isLoading: isCreateGameLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createGame: (payload) => dispatch(createGameAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateGameContainer);
