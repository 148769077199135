import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './styles.scss';

const Footer = () => (
  <Container fluid className="footer-container">
    <Row>
      <Col xs={6} sm="auto" className="text-center">
        <a
          href="https://privacy.paramount.com/policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </Col>
      <Col xs={6} sm="auto" className="text-center">
        <a
          href="https://privacy.paramount.com/en/policy#additional-information-us-states"
          target="_blank"
          rel="noreferrer"
        >
          California Notice
        </a>
      </Col>
      <Col xs={12} sm="auto" className="text-center">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="ot-sdk-show-settings"
        >
          Do Not Sell My Personal Information
        </a>
      </Col>
    </Row>
  </Container>
);

export default Footer;
