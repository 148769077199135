import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const ChangePassword = (props) => {
  const {
    error,
    isLoading,
    onSubmit,
  } = props;

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isButtonDisabled = (isLoading || !(currentPassword && newPassword));

  const {
    detail,
    errors: {
      current_password: [currentPasswordError] = [],
      new_password: [newPasswordError] = [],
    } = {},
  } = error;

  const shouldRenderFormError = (
    detail
    && !(currentPasswordError || newPasswordError)
  );

  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);
      setCurrentPassword('');
      setNewPassword('');
      if (!detail) {
        history.push('/staff/games');
      }
    }
  }, [isLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { currentPassword, newPassword };
    onSubmit(payload);
    setIsSubmitted(true);
  };

  return (
    <Container fluid className="change-password-container">
      <Form noValidate onSubmit={handleSubmit} className="change-password-form">
        <div className="pb-3">
          <h2 className="black-text">Change Password</h2>
        </div>
        <Form.Group className="form-group" controlId="current-password">
          <Form.Label className="black-text bold-text">
            Current Password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your current password"
            isInvalid={!!currentPasswordError}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          {currentPasswordError && (
            <Form.Control.Feedback type="invalid">
              {currentPasswordError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="form-group" controlId="password">
          <Form.Label className="black-text bold-text">
            New Password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter a new password"
            isInvalid={!!newPasswordError}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {newPasswordError && (
            <Form.Control.Feedback type="invalid">
              {newPasswordError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className="pb-3">
          <Button
            variant="blue"
            type="submit"
            disabled={isButtonDisabled}
            className="full-width"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Save'
            )}
          </Button>
          {shouldRenderFormError && (
            <div className="invalid-feedback">{detail}</div>
          )}
        </div>
      </Form>
    </Container>
  );
};

ChangePassword.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      current_password: PropTypes.arrayOf(PropTypes.string),
      new_password: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangePassword;
