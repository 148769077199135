import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loading from 'components/Loading';
import CreateInviteContainer from 'containers/CreateInviteContainer';
import './styles.scss';

const WEB_URL = process.env.REACT_APP_WEB_URL;

const Team = (props) => {
  const { team: { invites, users }, isLoading } = props;

  const [isInviteTeammateVisible, setIsInviteTeammateVisible] = useState(false);

  const combinedTeam = [
    ...users.sort().map((user) => ({ ...user, isUser: true })),
    ...invites.sort().map((invite) => ({ ...invite, isUser: false })),
  ];

  const handleCopyLink = (id) => {
    navigator.clipboard.writeText(`${WEB_URL}/staff/invite/${id}`);
  };

  const teamContent = combinedTeam.map(({
    id,
    firstName,
    lastName,
    email,
    isUser,
  }) => (
    <Row key={id} className="teammate-container">
      <Col>
        <div className="bold-text">{firstName} {lastName}</div>
      </Col>
      <Col>
        <div>{email}</div>
      </Col>
      <Col>
        <div className="text-end">
          {!isUser && (
            <Button
              variant="text"
              type="button"
              onClick={() => handleCopyLink(id)}
            >
              Copy invite link
            </Button>
          )}
        </div>
      </Col>
    </Row>
  ));

  return isLoading ? <Loading /> : (
    <Container fluid className="team-container">
      <CreateInviteContainer
        isVisible={isInviteTeammateVisible}
        onClose={() => setIsInviteTeammateVisible(false)}
      />
      <div className="pb-4">
        <h2>Team</h2>
      </div>
      <div className="pb-4">
        <Button
          variant="green"
          type="button"
          onClick={() => setIsInviteTeammateVisible(true)}
        >
          Invite Teammate
        </Button>
      </div>
      <div>
        {teamContent}
      </div>
    </Container>
  );
};

Team.propTypes = {
  team: PropTypes.shape({
    invites: PropTypes.arrayOf(PropTypes.shape({})),
    users: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Team;
