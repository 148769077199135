import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InviteForm from 'components/InviteForm';
import {
  clearInviteAction,
  createInviteAction,
} from 'js/actions/inviteActions';

const CreateInviteContainer = (props) => {
  const {
    clearInvite,
    createInvite,
    error,
    invite,
    isLoading,
    isVisible,
    onClose,
  } = props;

  const handleClose = () => {
    clearInvite();
    onClose();
  };

  return (
    <InviteForm
      error={error}
      header="Invite Details"
      invite={invite}
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={handleClose}
      onSubmit={createInvite}
    />
  );
};

CreateInviteContainer.propTypes = {
  clearInvite: PropTypes.func.isRequired,
  createInvite: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  invite: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { createInviteError },
    loading: { isCreateInviteLoading },
    invite,
  } = state;

  return {
    error: createInviteError || {},
    isLoading: isCreateInviteLoading,
    invite,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearInvite: () => dispatch(clearInviteAction()),
  createInvite: (payload) => dispatch(createInviteAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateInviteContainer);
