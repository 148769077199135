const getBoardsAction = (payload) => ({ type: 'GET_BOARDS', payload });

const receiveBoardsAction = (payload) => ({ type: 'RECEIVE_BOARDS', payload });

const createBoardAction = (payload) => ({ type: 'CREATE_BOARD', payload });

const updateBoardAction = (payload) => ({ type: 'UPDATE_BOARD', payload });

const receiveBoardAction = (payload) => ({ type: 'RECEIVE_BOARD', payload });

const deleteBoardAction = (payload) => ({ type: 'DELETE_BOARD', payload });

const removeBoardAction = (payload) => ({ type: 'REMOVE_BOARD', payload });

const clearBoardsAction = () => ({ type: 'CLEAR_BOARDS' });

const getActiveBoardAction = (payload) => ({
  type: 'GET_ACTIVE_BOARD',
  payload,
});

const receiveActiveBoardAction = (payload) => ({
  type: 'RECEIVE_ACTIVE_BOARD',
  payload,
});

const addEmailAction = (payload) => ({ type: 'ADD_EMAIL', payload });

export {
  addEmailAction,
  clearBoardsAction,
  createBoardAction,
  deleteBoardAction,
  getActiveBoardAction,
  getBoardsAction,
  receiveActiveBoardAction,
  receiveBoardsAction,
  receiveBoardAction,
  removeBoardAction,
  updateBoardAction,
};
