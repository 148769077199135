const fieldMap = {
  winningTitle: 'winning_title',
  winningBody: 'winning_body',
  losingTitle: 'losing_title',
  losingBody: 'losing_body',
  ctaUrl: 'cta_url',
  ctaText: 'cta_text',
  startAt: 'start_at',
  endAt: 'end_at',
};

const keyboardRows = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
  ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
  ['Z', 'X', 'C', 'V', 'B', 'N', 'M'],
];

const colors = {
  red: {
    hex: '#ff0000',
    emoji: '🔴',
  },
  orange: {
    hex: '#ffa500',
    emoji: '🟠',
  },
  yellow: {
    hex: '#ffff00',
    emoji: '🟡',
  },
  green: {
    hex: '#00ff00',
    emoji: '🟢',
  },
  blue: {
    hex: '#0000ff',
    emoji: '🔵',
  },
  purple: {
    hex: '#800080',
    emoji: '🟣',
  },
  brown: {
    hex: '#964b00',
    emoji: '🟤',
  },
  black: {
    hex: '#000000',
    emoji: '⚫',
  },
  white: {
    hex: '#ffffff',
    emoji: '⚪',
  },
};

export { colors, fieldMap, keyboardRows };
