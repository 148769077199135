import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import NavbarContainer from 'containers/NavbarContainer';

const PublicRouteContainer = ({ user, children, ...props }) => {
  const { isStaff, token } = user;

  return (
    <Route
      {...props}
      render={({ location }) => {
        const { state = {} } = location;
        const { from: { pathname } = {} } = state || {};

        return token ? (
          <Redirect
            to={pathname || '/staff/games'}
          />
        ) : (
          <>
            <NavbarContainer isStaff={!!token && isStaff} isLoggedIn={!!token} />
            {children}
          </>
        );
      }}
    />
  );
};

PublicRouteContainer.propTypes = {
  user: PropTypes.shape({
    isStaff: PropTypes.bool,
    token: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(
  mapStateToProps,
)(PublicRouteContainer);
