import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const AcceptInvite = (props) => {
  const {
    error,
    invite: {
      id,
      email,
      firstName: submittedFirstName,
      lastName: submittedLastName,
    },
    isLoading,
    onSubmit,
  } = props;

  const [firstName, setFirstName] = useState(submittedFirstName || '');
  const [lastName, setLastName] = useState(submittedLastName || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const isButtonDisabled = (
    isLoading
    || !(firstName && lastName && password)
    || (password !== confirmPassword)
  );

  const {
    detail,
    errors: {
      firstName: [firstNameError] = [],
      lastName: [lastNameError] = [],
      password: [passwordError] = [],
    } = {},
  } = error;

  const shouldRenderFormError = detail && !(
    firstNameError
    || lastNameError
    || passwordError
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      id,
      firstName,
      lastName,
      password,
    };
    onSubmit(payload);
  };

  return (
    <Container fluid className="accept-invite-container">
      <Form noValidate onSubmit={handleSubmit} className="accept-invite-form">
        <div className="pb-3">
          <h2 className="black-text">Accept Invite</h2>
        </div>
        <Row>
          <Col>
            <Form.Group className="form-group" controlId="first-name">
              <Form.Label className="black-text bold-text">
                First Name
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                isInvalid={!!firstNameError}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {firstNameError && (
                <Form.Control.Feedback type="invalid">
                  {firstNameError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="form-group" controlId="last-name">
              <Form.Label className="black-text bold-text">
                Last Name
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                isInvalid={!!lastNameError}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {lastNameError && (
                <Form.Control.Feedback type="invalid">
                  {lastNameError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="form-group" controlId="email">
          <Form.Label className="black-text bold-text">
            Email
          </Form.Label>
          <Form.Control
            type="email"
            disabled
            placeholder="Email address"
            value={email}
          />
        </Form.Group>
        <Form.Group className="form-group" controlId="password">
          <Form.Label className="black-text bold-text">
            Password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            isInvalid={!!passwordError}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <Form.Control.Feedback type="invalid">
              {passwordError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="form-group" controlId="confirm-password">
          <Form.Label className="black-text bold-text">
            Confirm Password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>
        <div className="pb-3">
          <Button
            variant="blue"
            type="submit"
            disabled={isButtonDisabled}
            className="full-width"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Accept Invite'
            )}
          </Button>
          {shouldRenderFormError && (
            <div className="invalid-feedback">{detail}</div>
          )}
        </div>
      </Form>
    </Container>
  );
};

AcceptInvite.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      email: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  invite: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AcceptInvite;
