import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const createReducer = (initialState, handlers) => (
  (state = initialState, action) => (action.type in handlers
    ? handlers[action.type](state, action)
    : state
  )
);

const createPersistedReducer = (initialState, handlers, key) => {
  const config = { key, storage };

  return persistReducer(config, createReducer(initialState, handlers));
};

const formatUser = (user) => {
  const {
    first_name: firstName,
    last_name: lastName,
    is_staff: isStaff,
    ...restOfUser
  } = user;

  return {
    ...restOfUser,
    firstName,
    lastName,
    isStaff,
  };
};

const formatInvite = (invite) => {
  const {
    first_name: firstName,
    last_name: lastName,
    ...restOfInvite
  } = invite;

  return {
    ...restOfInvite,
    firstName,
    lastName,
  };
};

const formatGame = (game) => {
  const {
    background_color: backgroundColor,
    text_color: textColor,
    primary_color: primaryColor,
    secondary_color: secondaryColor,
    primary_emoji: primaryEmoji,
    secondary_emoji: secondaryEmoji,
    tertiary_emoji: tertiaryEmoji,
    desktop_background: desktopBackground,
    mobile_background: mobileBackground,
    is_email_required: isEmailRequired,
    ...restOfGame
  } = game;

  return {
    ...restOfGame,
    backgroundColor,
    textColor,
    primaryColor,
    secondaryColor,
    primaryEmoji,
    secondaryEmoji,
    tertiaryEmoji,
    desktopBackground,
    mobileBackground,
    isEmailRequired,
  };
};

const formatBoard = (board) => {
  const {
    winning_title: winningTitle,
    winning_body: winningBody,
    losing_title: losingTitle,
    losing_body: losingBody,
    cta_url: ctaUrl,
    cta_text: ctaText,
    start_at: startAt,
    end_at: endAt,
    ...restOfBoard
  } = board;

  return {
    ...restOfBoard,
    winningTitle,
    winningBody,
    losingTitle,
    losingBody,
    ctaUrl,
    ctaText,
    startAt,
    endAt,
  };
};

export {
  createReducer,
  createPersistedReducer,
  formatBoard,
  formatGame,
  formatInvite,
  formatUser,
};
