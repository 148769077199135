import { createReducer, formatInvite } from './utils';

const receiveInvite = (inviteState, { payload }) => formatInvite(payload);

const clearInvite = () => ({});

const inviteReducer = createReducer({}, {
  RECEIVE_INVITE: receiveInvite,
  CLEAR_INVITE: clearInvite,
});

export default inviteReducer;
