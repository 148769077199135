import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Games from 'components/Games';

const GamesContainer = (props) => {
  const { games, isLoading } = props;
  return <Games games={games} isLoading={isLoading} />;
};

GamesContainer.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    games: { games },
    loading: { isGetGamesLoading },
  } = state;

  return {
    games,
    isLoading: isGetGamesLoading,
  };
};

export default connect(mapStateToProps)(GamesContainer);
