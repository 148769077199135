const receiveUserAction = (payload) => ({ type: 'RECEIVE_USER', payload });

const clearUserAction = () => ({ type: 'CLEAR_USER' });

const signUpAction = (payload) => ({ type: 'SIGN_UP', payload });

const logInAction = (payload) => ({ type: 'LOG_IN', payload });

const logOutAction = () => ({ type: 'LOG_OUT' });

const getUserAction = () => ({ type: 'GET_USER' });

const getUsersAction = () => ({ type: 'GET_USERS' });

const changePasswordAction = (payload) => ({ type: 'CHANGE_PASSWORD', payload });

export {
  changePasswordAction,
  clearUserAction,
  getUserAction,
  getUsersAction,
  logInAction,
  logOutAction,
  receiveUserAction,
  signUpAction,
};
