import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import NavbarContainer from 'containers/NavbarContainer';
import Loading from 'components/Loading';
import { getGamesAction } from 'js/actions/gameActions';
import { getInvitesAction } from 'js/actions/inviteActions';
import { getUserAction, getUsersAction } from 'js/actions/userActions';

const AuthenticatedRouteContainer = ({
  user: { isStaff, token },
  getGames,
  getInvites,
  getUser,
  getUsers,
  children,
  isLoading,
  ...props
}) => {
  const [isLoadStarted, setIsLoadStarted] = useState(false);

  useEffect(() => {
    if (token) {
      getUser();
      getGames();

      if (isStaff) {
        getUsers();
        getInvites();
      }

      setIsLoadStarted(true);
    }
  }, []);

  const content = isLoadStarted && !isLoading ? children : <Loading />;

  return (
    <Route
      {...props}
      render={({ location }) => (token ? (
        <>
          <NavbarContainer isStaff={!!token && isStaff} isLoggedIn={!!token} />
          {content}
        </>
      ) : (
        <Redirect
          to={{
            pathname: '/log-in',
            state: { from: isLoadStarted ? '' : location },
          }}
        />
      ))}
    />
  );
};

AuthenticatedRouteContainer.propTypes = {
  user: PropTypes.shape({
    isStaff: PropTypes.bool,
    token: PropTypes.string,
  }).isRequired,
  getGames: PropTypes.func.isRequired,
  getInvites: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    user,
    loading: { isGetUserLoading },
  } = state;

  return {
    user,
    isLoading: isGetUserLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGames: () => dispatch(getGamesAction()),
  getInvites: () => dispatch(getInvitesAction()),
  getUser: () => dispatch(getUserAction()),
  getUsers: () => dispatch(getUsersAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticatedRouteContainer);
