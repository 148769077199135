const getGamesAction = () => ({ type: 'GET_GAMES' });

const receiveGamesAction = (payload) => ({ type: 'RECEIVE_GAMES', payload });

const createGameAction = (payload) => ({ type: 'CREATE_GAME', payload });

const updateGameAction = (payload) => ({ type: 'UPDATE_GAME', payload });

const receiveGameAction = (payload) => ({ type: 'RECEIVE_GAME', payload });

const deleteGameAction = (payload) => ({ type: 'DELETE_GAME', payload });

const removeGameAction = (payload) => ({ type: 'REMOVE_GAME', payload });

const clearGamesAction = () => ({ type: 'CLEAR_GAMES' });

export {
  clearGamesAction,
  createGameAction,
  deleteGameAction,
  getGamesAction,
  receiveGamesAction,
  receiveGameAction,
  removeGameAction,
  updateGameAction,
};
