import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  receiveGameAction,
  receiveGamesAction,
  removeGameAction,
} from 'js/actions/gameActions';
import { formatGamePayload, getLoggedInHeaders } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const getGames = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetGamesLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/games/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getGamesError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveGamesAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetGamesLoading: false }));
};

const createGame = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isCreateGameLoading: true }));
  const { user: { token } } = getState();
  const { payload } = action;

  const response = await fetch(`${API_URL}/games/`, {
    method: 'POST',
    body: JSON.stringify(formatGamePayload(payload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ createGameError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveGameAction(responseJson));
  }

  dispatch(setLoadingAction({ isCreateGameLoading: false }));
};

const updateGame = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isUpdateGameLoading: true }));
  const { user: { token } } = getState();
  const { payload: { id, ...restOfPayload } } = action;

  const response = await fetch(`${API_URL}/games/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(formatGamePayload(restOfPayload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateGameError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveGameAction(responseJson));
  }

  dispatch(setLoadingAction({ isUpdateGameLoading: false }));
};

const deleteGame = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isDeleteGameLoading: true }));
  const { user: { token } } = getState();
  const { payload: id } = action;

  const response = await fetch(`${API_URL}/games/${id}/`, {
    method: 'DELETE',
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ deleteGameError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(removeGameAction(id));
  }

  dispatch(setLoadingAction({ isDeleteGameLoading: false }));
};

const gameMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'GET_GAMES':
      await getGames(action, store);
      break;
    case 'CREATE_GAME':
      await createGame(action, store);
      break;
    case 'UPDATE_GAME':
      await updateGame(action, store);
      break;
    case 'DELETE_GAME':
      await deleteGame(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default gameMiddleware;
