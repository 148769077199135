import { createPersistedReducer, formatGame } from './utils';

const receiveGames = (gameState, { payload }) => ({
  games: payload.map(formatGame),
});

const receiveGame = (gameState, { payload }) => {
  const { games } = gameState;
  const { id } = payload;
  const index = games.findIndex(({ id: gameId }) => id === gameId);

  return {
    games: [
      ...games.slice(0, index),
      formatGame(payload),
      ...games.slice(index + 1),
    ],
  };
};

const removeGame = (gameState, { payload: id }) => {
  const { games: prevGames } = gameState;
  const games = prevGames.filter((game) => game.id !== id);
  return { games };
};

const clearGames = () => ({ games: [] });

const gameReducer = createPersistedReducer({ games: [] }, {
  CLEAR_GAMES: clearGames,
  RECEIVE_GAME: receiveGame,
  RECEIVE_GAMES: receiveGames,
  REMOVE_GAME: removeGame,
}, 'games');

export default gameReducer;
