import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GameForm from 'components/GameForm';
import { updateGameAction } from 'js/actions/gameActions';

const UpdateGameContainer = (props) => {
  const {
    game,
    game: { id },
    updateGame,
    error,
    isLoading,
    isVisible,
    onClose,
  } = props;

  const handleSave = (payload) => {
    updateGame({ id, ...payload });
  };

  return (
    <GameForm
      game={game}
      error={error}
      header="Edit Game Settings"
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

UpdateGameContainer.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  updateGame: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { updateGameError },
    loading: { isUpdateGameLoading },
  } = state;

  return {
    error: updateGameError || {},
    isLoading: isUpdateGameLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateGame: (payload) => dispatch(updateGameAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateGameContainer);
