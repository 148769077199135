import React from 'react';

const ExampleThreeSvg = () => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42.5676" height="42.5676" rx="21.2838" fill="#404342" />
    <rect x="0.752514" y="0.752514" width="41.0625" height="41.0625" rx="20.5313" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M13.296 30L20.6644 20.0385V21.5019L13.7324 12.0282H15.9147L21.7427 19.9358L20.8955 19.9615L26.7235 12.0282H28.7774L21.9224 21.3735V20.0385L29.2652 30H27.0572L20.8441 21.5533H21.64L15.4783 30H13.296Z" fill="white" />
  </svg>
);

export default ExampleThreeSvg;
