import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EmailForm from 'components/EmailForm';
import { addEmailAction } from 'js/actions/boardActions';

const EmailContainer = (props) => {
  const {
    boardId,
    gameId,
    addEmail,
    error,
    isLoading,
    isVisible,
    onClose,
  } = props;

  const handleSubmit = (payload) => {
    addEmail({ boardId, gameId, ...payload });
  };

  return boardId ? (
    <EmailForm
      error={error}
      isLoading={isLoading}
      isVisible={isVisible}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  ) : null;
};

EmailContainer.propTypes = {
  boardId: PropTypes.string.isRequired,
  gameId: PropTypes.string.isRequired,
  addEmail: PropTypes.func.isRequired,
  error: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { addEmailError },
    loading: { isAddEmailLoading },
  } = state;

  return {
    error: addEmailError || {},
    isLoading: isAddEmailLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addEmail: (payload) => dispatch(addEmailAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailContainer);
