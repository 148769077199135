import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const LogIn = (props) => {
  const {
    error,
    isLoading,
    onSubmit,
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isButtonDisabled = (isLoading || !(email && password));

  const {
    detail,
    errors: {
      email: [emailError] = [],
      password: [passwordError] = [],
    } = {},
  } = error;

  const shouldRenderFormError = detail && !(emailError || passwordError);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { email, password };
    onSubmit(payload);
  };

  return (
    <Container fluid className="log-in-container">
      <Form noValidate onSubmit={handleSubmit} className="log-in-form">
        <div className="pb-3">
          <h2 className="black-text">Log In</h2>
        </div>
        <Form.Group className="form-group" controlId="email">
          <Form.Label className="black-text bold-text">
            Email
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email address"
            isInvalid={!!emailError}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <Form.Control.Feedback type="invalid">
              {emailError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="form-group" controlId="password">
          <Form.Label className="black-text bold-text">
            Password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            isInvalid={!!passwordError}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <Form.Control.Feedback type="invalid">
              {passwordError}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className="pb-3">
          <Button
            variant="blue"
            type="submit"
            disabled={isButtonDisabled}
            className="full-width"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Log In'
            )}
          </Button>
          {shouldRenderFormError && (
            <div className="invalid-feedback">{detail}</div>
          )}
        </div>
      </Form>
    </Container>
  );
};

LogIn.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({
      email: PropTypes.arrayOf(PropTypes.string),
      password: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LogIn;
