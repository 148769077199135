import React from 'react';
import PropTypes from 'prop-types';
import { hexToRgba } from 'js/utils';
import './styles.scss';

const KeyboardCircle = (props) => {
  const {
    backgroundColor,
    letter,
    onClick,
    isDisabled,
  } = props;
  const background = isDisabled ? 'transparent' : hexToRgba(backgroundColor, 1);

  return (
    <button
      style={{ background }}
      className="keyboard-circle"
      type="button"
      onClick={() => onClick(letter)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <text
          x="50%"
          y="55%"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {letter}
        </text>
      </svg>
    </button>
  );
};

KeyboardCircle.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default KeyboardCircle;
