import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { words } from 'js/constants/words';
import { formatDate, formatTime } from 'js/utils';
import './styles.scss';

const BoardForm = (props) => {
  const {
    board: {
      title: existingTitle,
      image: existingImage,
      answer: existingAnswer,
      guesses: existingGuesses,
      winningTitle: existingWinningTitle,
      winningBody: existingWinningBody,
      losingTitle: existingLosingTitle,
      losingBody: existingLosingBody,
      ctaUrl: existingCtaUrl,
      ctaText: existingCtaText,
      startAt: existingStartAt,
      endAt: existingEndAt,
    } = {},
    error: {
      detail,
      errors: {
        title: [titleError] = [],
        image: [imageError] = [],
        answer: [answerError] = [],
        guesses: [guessesError] = [],
        winning_title: [winningTitleError] = [],
        winning_body: [winningBodyError] = [],
        losing_title: [losingTitleError] = [],
        losing_body: [losingBodyError] = [],
        cta_url: [ctaUrlError] = [],
        cta_text: [ctaTextError] = [],
        start_at: [startAtError] = [],
        end_at: [endAtError] = [],
      } = {},
    },
    header,
    isLoading,
    isVisible,
    onClose,
    onSave,
  } = props;

  const existingStartDate = existingStartAt && formatDate(existingStartAt);
  const existingStartTime = existingStartAt && formatTime(existingStartAt);
  const existingEndDate = existingEndAt && formatDate(existingEndAt);
  const existingEndTime = existingEndAt && formatTime(existingEndAt);

  const [title, setTitle] = useState(existingTitle || '');
  const [image, setImage] = useState(existingImage || '');
  const [answer, setAnswer] = useState(existingAnswer || '');
  const [guesses, setGuesses] = useState(existingGuesses || '');
  const [winningTitle, setWinningTitle] = useState(existingWinningTitle || '');
  const [winningBody, setWinningBody] = useState(existingWinningBody || '');
  const [losingTitle, setLosingTitle] = useState(existingLosingTitle || '');
  const [losingBody, setLosingBody] = useState(existingLosingBody || '');
  const [ctaUrl, setCtaUrl] = useState(existingCtaUrl || '');
  const [ctaText, setCtaText] = useState(existingCtaText || '');
  const [startDate, setStartDate] = useState(existingStartDate || '');
  const [startTime, setStartTime] = useState(existingStartTime || '');
  const [endDate, setEndDate] = useState(existingEndDate || '');
  const [endTime, setEndTime] = useState(existingEndTime || '');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);

      if (!detail) {
        onClose();
        setTitle('');
        setAnswer('');
        setGuesses('');
        setWinningTitle('');
        setWinningBody('');
        setLosingTitle('');
        setLosingBody('');
        setCtaUrl('');
        setCtaText('');
        setStartDate('');
        setStartTime('');
        setEndDate('');
        setEndTime('');
      }
    }
  }, [isLoading]);

  const isButtonDisabled = (isLoading || !(
    title
    && answer
    && answer.length <= 9
    && answer.length >= 4
    && guesses
    && guesses <= 7
    && guesses >= 5
    && winningTitle
    && winningBody
    && losingTitle
    && losingBody
    && ctaUrl
    && ctaText
    && startDate
    && startTime
    && endDate
    && endTime
    && words[answer.length].includes(answer.toLowerCase())
  ));

  const shouldRenderFormError = detail && !(
    titleError
    || answerError
    || guessesError
    || winningTitleError
    || winningBodyError
    || losingTitleError
    || losingBodyError
    || ctaUrlError
    || ctaTextError
    || startAtError
    || endAtError
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const startAt = new Date(`${startDate} ${startTime}`).toISOString();
    const endAt = new Date(`${endDate} ${endTime}`).toISOString();

    const payload = {
      title,
      image,
      answer: answer.toLowerCase(),
      guesses: parseInt(guesses),
      winningTitle,
      winningBody,
      losingTitle,
      losingBody,
      ctaUrl,
      ctaText,
      startAt,
      endAt,
    };

    setIsSubmitted(true);
    onSave(payload);
  };

  return (
    <Modal centered show={isVisible} onHide={onClose}>
      <Container fluid className="board-form-container">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-3 text-center">
            <h3>{header}</h3>
          </div>
          <Form.Group className="form-group" controlId="title">
            <Form.Label className="black-text bold-text">
              Title
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a title for this board"
              isInvalid={!!titleError}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {titleError && (
              <Form.Control.Feedback type="invalid">
                {titleError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="title-image">
            <Form.Label className="black-text bold-text">
              Title Image
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Please enter the image url"
              isInvalid={!!imageError}
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
            {imageError && (
              <Form.Control.Feedback type="invalid">
                {imageError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="answer">
                <Form.Label className="black-text bold-text">
                  Answer
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Between 4-9 letters"
                  isInvalid={!!answerError}
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                />
                {answerError && (
                  <Form.Control.Feedback type="invalid">
                    {answerError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="guesses">
                <Form.Label className="black-text bold-text">
                  Number of Guesses
                </Form.Label>
                <Form.Control
                  type="number"
                  min={5}
                  max={7}
                  placeholder="Between 5-7 letters"
                  isInvalid={!!guessesError}
                  value={guesses}
                  onChange={(e) => setGuesses(e.target.value)}
                />
                {guessesError && (
                  <Form.Control.Feedback type="invalid">
                    {guessesError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group" controlId="winning-title">
            <Form.Label className="black-text bold-text">
              Winning Message Title
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a header for winning players"
              isInvalid={!!winningTitleError}
              value={winningTitle}
              onChange={(e) => setWinningTitle(e.target.value)}
            />
            {winningTitleError && (
              <Form.Control.Feedback type="invalid">
                {winningTitleError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="winning-body">
            <Form.Label className="black-text bold-text">
              Winning Message Body
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter a message for winning players"
              isInvalid={!!winningBodyError}
              value={winningBody}
              onChange={(e) => setWinningBody(e.target.value)}
            />
            {winningBodyError && (
              <Form.Control.Feedback type="invalid">
                {winningBodyError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="losing-title">
            <Form.Label className="black-text bold-text">
              Losing Message Title
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a header for losing players"
              isInvalid={!!losingTitleError}
              value={losingTitle}
              onChange={(e) => setLosingTitle(e.target.value)}
            />
            {losingTitleError && (
              <Form.Control.Feedback type="invalid">
                {losingTitleError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="losing-body">
            <Form.Label className="black-text bold-text">
              Losing Message Body
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter a message for losing players"
              isInvalid={!!losingBodyError}
              value={losingBody}
              onChange={(e) => setLosingBody(e.target.value)}
            />
            {losingBodyError && (
              <Form.Control.Feedback type="invalid">
                {losingBodyError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="cta-url">
            <Form.Label className="black-text bold-text">
              Post-Game Link
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="https://www.paramountplus.com"
              isInvalid={!!ctaUrlError}
              value={ctaUrl}
              onChange={(e) => setCtaUrl(e.target.value)}
            />
            {ctaUrlError && (
              <Form.Control.Feedback type="invalid">
                {ctaUrlError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="cta-text">
            <Form.Label className="black-text bold-text">
              Post-Game Button CTA
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={'e.g. "Continue to Paramount Plus"'}
              isInvalid={!!ctaTextError}
              value={ctaText}
              onChange={(e) => setCtaText(e.target.value)}
            />
            {ctaTextError && (
              <Form.Control.Feedback type="invalid">
                {ctaTextError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="start-date">
                <Form.Label className="black-text bold-text">
                  Start Date
                </Form.Label>
                <Form.Control
                  type="date"
                  isInvalid={!!startAtError}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                {startAtError && (
                  <Form.Control.Feedback type="invalid">
                    {startAtError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="start-time">
                <Form.Label className="black-text bold-text">
                  Start Time
                </Form.Label>
                <Form.Control
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="end-date">
                <Form.Label className="black-text bold-text">
                  End Date
                </Form.Label>
                <Form.Control
                  type="date"
                  isInvalid={!!endAtError}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                {endAtError && (
                  <Form.Control.Feedback type="invalid">
                    {endAtError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="end-time">
                <Form.Label className="black-text bold-text">
                  End Time
                </Form.Label>
                <Form.Control
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="pb-3">
            <Button
              variant="blue"
              type="submit"
              disabled={isButtonDisabled}
              className="full-width"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Save'
              )}
            </Button>
          </div>
          <div>
            <Button
              variant="transparent"
              type="button"
              onClick={onClose}
              className="full-width error-red-text"
            >
              Cancel
            </Button>
          </div>
          {shouldRenderFormError && (
            <div className="invalid-feedback">{detail}</div>
          )}
        </Form>
      </Container>
    </Modal>
  );
};

BoardForm.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({}),
  }).isRequired,
  board: PropTypes.shape({}).isRequired,
  header: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BoardForm;
