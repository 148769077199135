import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  clearInviteAction,
  receiveInviteAction,
} from 'js/actions/inviteActions';
import {
  receiveTeamAction,
  receiveTeammateAction,
} from 'js/actions/teamActions';
import { receiveUserAction } from 'js/actions/userActions';
import { formatUserPayload, getLoggedInHeaders, publicHeaders } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const acceptInvite = async (action, { dispatch }) => {
  const { payload: { id, ...payload } } = action;

  dispatch(setLoadingAction({ isAcceptInviteLoading: true }));

  const response = await fetch(`${API_URL}/invites/${id}/accept/`, {
    method: 'POST',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ acceptInviteError: responseJson }));
  } else {
    const { user, token } = responseJson;
    dispatch(clearErrorsAction());
    dispatch(receiveUserAction({ ...user, token }));
    dispatch(clearInviteAction());
  }

  dispatch(setLoadingAction({ isAcceptInviteLoading: false }));
};

const createInvite = async (action, { dispatch, getState }) => {
  const { payload } = action;
  const { user: { token } } = getState();

  dispatch(setLoadingAction({ isCreateInviteLoading: true }));

  const response = await fetch(`${API_URL}/invites/`, {
    method: 'POST',
    body: JSON.stringify(formatUserPayload(payload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ createInviteError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveTeammateAction(responseJson));
    dispatch(receiveInviteAction(responseJson));
  }

  dispatch(setLoadingAction({ isCreateInviteLoading: false }));
};

const getInvite = async (action, { dispatch }) => {
  const { payload: { id } } = action;

  dispatch(setLoadingAction({ isGetInviteLoading: true }));

  const response = await fetch(`${API_URL}/invites/${id}/`, {
    method: 'GET',
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getInviteError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveInviteAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetInviteLoading: false }));
};

const getInvites = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetInvitesLoading: true }));
  const { user: { token } } = getState();

  const response = await fetch(`${API_URL}/invites/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getInvitesError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveTeamAction({ invites: responseJson }));
  }

  dispatch(setLoadingAction({ isGetInvitesLoading: false }));
};

const inviteMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'ACCEPT_INVITE':
      await acceptInvite(action, store);
      break;
    case 'CREATE_INVITE':
      await createInvite(action, store);
      break;
    case 'GET_INVITE':
      await getInvite(action, store);
      break;
    case 'GET_INVITES':
      await getInvites(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default inviteMiddleware;
