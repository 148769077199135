import React from 'react';
import PropTypes from 'prop-types';
import AnswerCircle from 'components/AnswerCircle';
import './styles.scss';

const INCORRECT_COLOR = '#747877';

const AnswerRow = (props) => {
  const {
    answer,
    letters,
    primaryColor,
    secondaryColor,
    isInvalid,
    isSubmitted,
    isSigilGame,
  } = props;

  const colors = new Array(answer.length).fill({
    backgroundColor: '#000000',
    backgroundOpacity: 0,
    isCorrect: false,
  });

  if (isSubmitted) {
    const unmatched = {};

    for (let i = 0; i < answer.length; i++) {
      const letter = answer[i];
      if (letter === letters[i]) {
        colors[i] = {
          backgroundColor: primaryColor,
          backgroundOpacity: 1,
          isCorrect: true,
        };
      } else {
        unmatched[letter] = letter in unmatched ? unmatched[letter] + 1 : 1;
      }
    }

    for (let i = 0; i < answer.length; i++) {
      const letter = letters[i];
      if (letter !== answer[i] && unmatched[letter]) {
        colors[i] = {
          backgroundColor: secondaryColor,
          backgroundOpacity: 1,
          isCorrect: false,
        };
        unmatched[letter]--;
      } else if (letter !== answer[i]) {
        colors[i] = {
          backgroundColor: INCORRECT_COLOR,
          backgroundOpacity: 0.5,
          isCorrect: false,
        };
      }
    }
  }

  const circles = colors.map(({
    backgroundColor,
    backgroundOpacity,
    isCorrect,
  }, i) => (
    <AnswerCircle
      key={i}
      backgroundColor={backgroundColor}
      backgroundOpacity={backgroundOpacity}
      letter={i < letters.length ? letters[i] : ''}
      isCorrect={isCorrect}
      isSigilGame={isSigilGame}
    />
  ));

  const className = isInvalid ? 'answer-row invalid' : 'answer-row';

  return (
    <div xs={answer.length} className={className}>
      {circles}
    </div>
  );
};

AnswerRow.propTypes = {
  answer: PropTypes.string.isRequired,
  letters: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isSigilGame: PropTypes.bool.isRequired,
};

export default AnswerRow;
