import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const EmailForm = (props) => {
  const {
    error: {
      detail,
      errors: {
        email_error: [emailError] = [],
        is_opted_in_error: [isOptedInError] = [],
      } = {},
    },
    isLoading,
    isVisible,
    onClose,
    onSubmit,
  } = props;

  const [email, setEmail] = useState('');
  const [isOptedIn, setIsOptedIn] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);

      if (!detail) {
        onClose();
      }
    }
  }, [isLoading]);

  const isButtonDisabled = (isLoading || !email);

  const shouldRenderFormError = detail && !(emailError || isOptedInError);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { email, isOptedIn };

    setIsSubmitted(true);
    onSubmit(payload);
  };

  return (
    <Modal centered show={isVisible}>
      <Container fluid className="game-form-container">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-3 text-center">
            <h3>Enter Your Email To Continue</h3>
          </div>
          <Form.Group className="form-group" controlId="email">
            <Form.Label className="black-text bold-text">
              Email address
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your email address"
              isInvalid={!!emailError}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="is-opted-in">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={isOptedIn}
                onChange={() => setIsOptedIn(!isOptedIn)}
              />
              <Form.Check.Label className="black-text">
                I would like to receive emails about news and promotions related to this product.
              </Form.Check.Label>
            </Form.Check>
            {isOptedInError && (
              <Form.Control.Feedback type="invalid">
                {isOptedInError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="pb-3">
            <Button
              variant="blue"
              type="submit"
              disabled={isButtonDisabled}
              className="full-width"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Continue'
              )}
            </Button>
          </div>
          {shouldRenderFormError && (
            <div className="invalid-feedback">{detail}</div>
          )}
        </Form>
      </Container>
    </Modal>
  );
};

EmailForm.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({}),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailForm;
