const fieldMap = {
  backgroundColor: 'background_color',
  textColor: 'text_color',
  primaryColor: 'primary_color',
  secondaryColor: 'secondary_color',
  primaryEmoji: 'primary_emoji',
  secondaryEmoji: 'secondary_emoji',
  tertiaryEmoji: 'tertiary_emoji',
  desktopBackground: 'desktop_background',
  mobileBackground: 'mobile_background',
  isEmailRequired: 'is_email_required',
};

export { fieldMap };
