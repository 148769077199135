import { clearErrorsAction, receiveErrorAction } from 'js/actions/errorActions';
import { setLoadingAction } from 'js/actions/loadingActions';
import {
  clearBoardsAction,
  receiveActiveBoardAction,
  receiveBoardAction,
  receiveBoardsAction,
  removeBoardAction,
} from 'js/actions/boardActions';
import { formatBoardPayload, getLoggedInHeaders, publicHeaders } from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const getBoards = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isGetBoardsLoading: true }));
  const { user: { token } } = getState();
  const { payload: gameId } = action;

  const response = await fetch(`${API_URL}/games/${gameId}/boards/`, {
    method: 'GET',
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getBoardsError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveBoardsAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetBoardsLoading: false }));
};

const createBoard = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isCreateBoardLoading: true }));
  const { user: { token } } = getState();
  const { payload: { gameId, ...restOfPayload } } = action;

  const response = await fetch(`${API_URL}/games/${gameId}/boards/`, {
    method: 'POST',
    body: JSON.stringify(formatBoardPayload(restOfPayload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ createBoardError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveBoardAction(responseJson));
  }

  dispatch(setLoadingAction({ isCreateBoardLoading: false }));
};

const updateBoard = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isUpdateBoardLoading: true }));
  const { user: { token } } = getState();
  const { payload: { id, gameId, ...restOfPayload } } = action;

  const response = await fetch(`${API_URL}/games/${gameId}/boards/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(formatBoardPayload(restOfPayload)),
    headers: getLoggedInHeaders(token),
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ updateBoardError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveBoardAction(responseJson));
  }

  dispatch(setLoadingAction({ isUpdateBoardLoading: false }));
};

const deleteBoard = async (action, { dispatch, getState }) => {
  dispatch(setLoadingAction({ isDeleteBoardLoading: true }));
  const { user: { token } } = getState();
  const { payload: { id, gameId } } = action;
  dispatch(removeBoardAction(id));

  const response = await fetch(`${API_URL}/games/${gameId}/boards/${id}/`, {
    method: 'DELETE',
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ deleteBoardError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
  }

  dispatch(setLoadingAction({ isDeleteBoardLoading: false }));
};

const getActiveBoard = async (action, { dispatch }) => {
  dispatch(setLoadingAction({ isGetActiveBoardLoading: true }));
  const { payload: slug } = action;

  const response = await fetch(`${API_URL}/games/${slug}/active/`, {
    method: 'GET',
    headers: publicHeaders,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    dispatch(receiveErrorAction({ getActiveBoardError: responseJson }));
    dispatch(clearBoardsAction());
  } else {
    dispatch(clearErrorsAction());
    dispatch(receiveActiveBoardAction(responseJson));
  }

  dispatch(setLoadingAction({ isGetActiveBoardLoading: false }));
};

const addEmail = async (action, { dispatch }) => {
  dispatch(setLoadingAction({ isAddEmailLoading: true }));
  const {
    payload: {
      boardId,
      gameId,
      email,
      isOptedIn,
    },
  } = action;

  const response = await fetch(
    `${API_URL}/games/${gameId}/boards/${boardId}/emails/`,
    {
      method: 'POST',
      body: JSON.stringify({ email, is_opted_in: isOptedIn }),
      headers: publicHeaders,
    },
  );

  if (!response.ok) {
    const responseJson = await response.json();
    dispatch(receiveErrorAction({ addEmailError: responseJson }));
  } else {
    dispatch(clearErrorsAction());
  }

  dispatch(setLoadingAction({ isAddEmailLoading: false }));
};

const boardMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case 'GET_BOARDS':
      await getBoards(action, store);
      break;
    case 'CREATE_BOARD':
      await createBoard(action, store);
      break;
    case 'UPDATE_BOARD':
      await updateBoard(action, store);
      break;
    case 'DELETE_BOARD':
      await deleteBoard(action, store);
      break;
    case 'GET_ACTIVE_BOARD':
      await getActiveBoard(action, store);
      break;
    case 'ADD_EMAIL':
      await addEmail(action, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default boardMiddleware;
