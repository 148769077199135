import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import CreateGameContainer from 'containers/CreateGameContainer';
import './styles.scss';

const WEB_URL = process.env.REACT_APP_WEB_URL;

const Games = (props) => {
  const { games, isLoading } = props;

  const [isCreateGameVisible, setIsCreateGameVisible] = useState(false);

  const gamesContent = games.map(({ id, name, slug }) => (
    <Col key={id}>
      <Link to={`/staff/games/${id}`} className="p-0 no-underline">
        <div className="data-container">
          <h3>{name}</h3>
          <div className="small-text text-wrap">
            <span className="bold-text">URL: </span>
            {WEB_URL}/{slug}
          </div>
        </div>
      </Link>
    </Col>
  ));

  return isLoading ? <Loading /> : (
    <Container fluid className="games-container">
      <CreateGameContainer
        isVisible={isCreateGameVisible}
        onClose={() => setIsCreateGameVisible(false)}
      />
      <div className="pb-4">
        <h2>Games</h2>
      </div>
      <div className="pb-4">
        <Button
          variant="green"
          type="button"
          onClick={() => setIsCreateGameVisible(true)}
        >
          New Game
        </Button>
      </div>
      <Row xs={1} sm={2} md={3}>
        {gamesContent}
      </Row>
    </Container>
  );
};

Games.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Games;
