import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import Footer from 'components/Footer';
import AcceptInviteContainer from 'containers/AcceptInviteContainer';
import AuthenticatedRouteContainer from 'containers/AuthenticatedRouteContainer';
import ChangePasswordContainer from 'containers/ChangePasswordContainer';
import GameContainer from 'containers/GameContainer';
import GamesContainer from 'containers/GamesContainer';
import NotFoundContainer from 'containers/NotFoundContainer';
import LogInContainer from 'containers/LogInContainer';
import PlayContainer from 'containers/PlayContainer';
import PublicRouteContainer from 'containers/PublicRouteContainer';
import TeamContainer from 'containers/TeamContainer';
import { clearErrorsAction } from 'js/actions/errorActions';
import { resetLoadingAction } from 'js/actions/loadingActions';

const RootContainer = (props) => {
  const { clearErrors, resetLoading } = props;

  const history = useHistory();

  useEffect(() => history.listen(() => {
    clearErrors();
    resetLoading();
  }), []);

  return (
    <div className="scroll-container full-height">
      <Switch>
        <PublicRouteContainer path="/staff/invite/:inviteId">
          <AcceptInviteContainer />
        </PublicRouteContainer>
        <PublicRouteContainer path="/log-in">
          <LogInContainer />
        </PublicRouteContainer>
        <AuthenticatedRouteContainer path="/staff/games/:gameId">
          <GameContainer />
        </AuthenticatedRouteContainer>
        <AuthenticatedRouteContainer path="/staff/games">
          <GamesContainer />
        </AuthenticatedRouteContainer>
        <AuthenticatedRouteContainer path="/staff/team">
          <TeamContainer />
        </AuthenticatedRouteContainer>
        <AuthenticatedRouteContainer path="/staff/change-password">
          <ChangePasswordContainer />
        </AuthenticatedRouteContainer>
        <Route path="/not-found">
          <NotFoundContainer />
        </Route>
        <Route path="/:slug">
          <PlayContainer />
        </Route>
        <Route path="/">
          <NotFoundContainer />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

RootContainer.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  resetLoading: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrorsAction()),
  resetLoading: () => dispatch(resetLoadingAction()),
});

export default connect(
  null,
  mapDispatchToProps,
)(RootContainer);
