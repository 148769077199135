const acceptInviteAction = (payload) => ({ type: 'ACCEPT_INVITE', payload });

const clearInviteAction = () => ({ type: 'CLEAR_INVITE' });

const createInviteAction = (payload) => ({ type: 'CREATE_INVITE', payload });

const getInviteAction = (payload) => ({ type: 'GET_INVITE', payload });

const getInvitesAction = () => ({ type: 'GET_INVITES' });

const receiveInviteAction = (payload) => ({ type: 'RECEIVE_INVITE', payload });

export {
  acceptInviteAction,
  clearInviteAction,
  createInviteAction,
  getInviteAction,
  getInvitesAction,
  receiveInviteAction,
};
