import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const WEB_URL = process.env.REACT_APP_WEB_URL;

const InviteForm = (props) => {
  const {
    error: {
      detail,
      errors: {
        first_name: [firstNameError] = [],
        last_name: [lastNameError] = [],
        email: [emailError] = [],
      } = {},
    },
    header,
    invite: { id },
    isLoading,
    isVisible,
    onClose,
    onSubmit,
  } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);
    }
  }, [isLoading]);

  const isButtonDisabled = (isLoading || !(
    firstName
    && lastName
    && email
  ));

  const shouldRenderFormError = detail && !(
    firstNameError
    || lastNameError
    || emailError
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { firstName, lastName, email };

    setIsSubmitted(true);
    onSubmit(payload);
  };

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setIsSubmitted(false);
    onClose();
  };

  return (
    <Modal centered show={isVisible} onHide={handleClose}>
      <Container fluid className="invite-form-container">
        {id ? (
          <div>
            <div className="pb-3 text-center">
              <h3>Invite Link</h3>
            </div>
            <div className="pb-3">{`${WEB_URL}/staff/invite/${id}`}</div>
            <div>
              <Button
                variant="blue"
                type="button"
                onClick={handleClose}
                className="full-width"
              >
                Close
              </Button>
            </div>
          </div>
        ) : (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="pb-3 text-center">
              <h3>{header}</h3>
            </div>
            <Row>
              <Col>
                <Form.Group className="form-group" controlId="first-name">
                  <Form.Label className="black-text bold-text">
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    isInvalid={!!firstNameError}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {firstNameError && (
                    <Form.Control.Feedback type="invalid">
                      {firstNameError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="last-name">
                  <Form.Label className="black-text bold-text">
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    isInvalid={!!lastNameError}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {lastNameError && (
                    <Form.Control.Feedback type="invalid">
                      {lastNameError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="form-group" controlId="email">
              <Form.Label className="black-text bold-text">
                Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                isInvalid={!!emailError}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="pb-3">
              <Button
                variant="blue"
                type="submit"
                disabled={isButtonDisabled}
                className="full-width"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Submit'
                )}
              </Button>
            </div>
            <div>
              <Button
                variant="transparent"
                type="button"
                onClick={handleClose}
                className="full-width error-red-text"
              >
                Cancel
              </Button>
            </div>
            {shouldRenderFormError && (
              <div className="invalid-feedback">{detail}</div>
            )}
          </Form>
        )}
      </Container>
    </Modal>
  );
};

InviteForm.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({}),
  }).isRequired,
  header: PropTypes.string.isRequired,
  invite: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InviteForm;
