import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import ExampleOneSvg from 'assets/images/example_one';
import ExampleTwoSvg from 'assets/images/example_two';
import ExampleThreeSvg from 'assets/images/example_three';
import './styles.scss';

const Instructions = (props) => {
  const {
    guesses,
    primaryColor,
    secondaryColor,
    isVisible,
    onClose,
  } = props;

  return (
    <Modal centered show={isVisible} onHide={onClose}>
      <Container fluid className="game-over-container">
        <div className="pb-3 text-center">
          <h3>How To Play</h3>
        </div>
        <div className="pb-3 text-center medium-gray-text">
          You'll have {guesses} guesses to solve the game. The colors on the
          board will indicate your progress.
        </div>
        <div className="text-center">
          <div className="pb-2">
            <h4>Examples</h4>
          </div>
          <div className="pb-2">
            <ExampleOneSvg color={primaryColor} />
          </div>
          <div className="text-center medium-gray-text pb-3">
            "L" is the correct letter in the correct place.
          </div>
          <div className="pb-2">
            <ExampleTwoSvg color={secondaryColor} />
          </div>
          <div className="text-center medium-gray-text pb-3">
            "A" is the correct letter in the wrong place.
          </div>
          <div className="pb-2">
            <ExampleThreeSvg />
          </div>
          <div className="text-center medium-gray-text pb-3">
            Letters in gray are not in the word.
          </div>
        </div>
        <div className="w-100">
          <Button
            variant="blue"
            type="button"
            className="w-100 no-underline"
            onClick={onClose}
          >
            Got it
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

Instructions.propTypes = {
  guesses: PropTypes.number.isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Instructions;
