import amplitude from 'amplitude-js';
import { useEffect, useState } from 'react';

const formatDateString = (date) => (
  new Date(Date.parse(date)).toLocaleString('en-US')
);

const formatDate = (date) => {
  const localDate = new Date(Date.parse(date));
  const year = localDate.getFullYear();
  const month = localDate.getMonth() + 1;
  const monthString = month < 10 ? `0${month}` : month;
  const day = localDate.getDate();
  const dayString = day < 10 ? `0${day}` : day;
  return `${year}-${monthString}-${dayString}`;
};

const formatTime = (date) => {
  const localDate = new Date(Date.parse(date));
  const hour = localDate.getHours();
  const hourString = hour < 10 ? `0${hour}` : hour;
  const minute = localDate.getMinutes();
  const minuteString = minute < 10 ? `0${minute}` : minute;
  return `${hourString}:${minuteString}`;
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const useMediaQuery = (query) => {
  const media = window.matchMedia(query);
  const [isMatched, setIsMatched] = useState(media.matches);

  useEffect(() => {
    const listener = () => {
      setIsMatched(media.matches);
    };
    media.addListener(listener);

    return () => media.removeListener(listener);
  }, []);

  return isMatched;
};

const logEvent = (event, eventProperties) => {
  /* global cbsoptanon */
  cbsoptanon.cmd.push((cmp) => {
    cmp.ot.performanceAllowed((allowed) => {
      if (allowed) {
        amplitude.getInstance().init(
          process.env.REACT_APP_AMPLITUDE_API_KEY,
        );

        amplitude.getInstance().logEvent(event, eventProperties);
      }
    });
  });
};

export {
  formatDateString,
  formatDate,
  formatTime,
  hexToRgba,
  logEvent,
  useMediaQuery,
};
