import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import { ReactComponent as EditSvg } from 'assets/images/edit.svg';
import Loading from 'components/Loading';
import CreateBoardContainer from 'containers/CreateBoardContainer';
import UpdateBoardContainer from 'containers/UpdateBoardContainer';
import UpdateGameContainer from 'containers/UpdateGameContainer';
import { formatDateString } from 'js/utils';
import './styles.scss';

const Game = (props) => {
  const {
    boards,
    game,
    isLoading,
    onDuplicate,
    onRemove,
  } = props;

  const [isUpdateGameVisible, setIsUpdateGameVisible] = useState(false);
  const [isCreateBoardVisible, setIsCreateBoardVisible] = useState(false);
  const [isCompletedVisible, setIsCompletedVisible] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState(undefined);

  const { id: gameId, name } = game;

  const boardsContent = boards.filter(({ endAt }) => (
    isCompletedVisible || new Date() < new Date(endAt)
  )).sort(({ startAt: a }, { startAt: b }) => (
    a < b ? -1 : 1
  )).map((board) => {
    const {
      id,
      title,
      answer,
      startAt,
      endAt,
    } = board;

    const now = new Date();
    const start = new Date(startAt);
    const end = new Date(endAt);

    let className;
    let scheduleText;
    if (now > end) {
      className = 'black-text';
      scheduleText = 'COMPLETED';
    } else if (now < start) {
      className = 'bright-blue-text';
      scheduleText = 'SCHEDULED';
    } else {
      className = 'success-green-text';
      scheduleText = 'ACTIVE';
    }

    return (
      <Col key={id}>
        <div className="data-container edit-container">
          <button
            type="button"
            className="edit-button"
            onClick={() => setSelectedBoard(board)}
          >
            <EditSvg />
          </button>
          <h3>{title}</h3>
          <div className="small-text">
            <span className="bold-text">Answer: </span>
            {answer}
          </div>
          <div className="small-text">
            <span className="bold-text">Start: </span>
            {formatDateString(startAt)}
          </div>
          <div className="small-text">
            <span className="bold-text">End: </span>
            {formatDateString(endAt)}
          </div>
          <div className={className}>
            {scheduleText}
          </div>
          <div className="pt-2">
            <span className="ps-2">
              <Button
                variant="green"
                type="button"
                disabled={isLoading}
                onClick={() => onDuplicate(board)}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : 'Duplicate'}
              </Button>
            </span>
            <span className="pe-2">
              <Button
                variant="transparent"
                type="button"
                onClick={() => onRemove({ id })}
              >
                Remove
              </Button>
            </span>
          </div>
        </div>
      </Col>
    );
  });

  return isLoading ? <Loading /> : (
    <Container fluid className="game-container">
      <UpdateGameContainer
        game={game}
        isVisible={isUpdateGameVisible}
        onClose={() => setIsUpdateGameVisible(false)}
      />
      <CreateBoardContainer
        gameId={gameId}
        isVisible={isCreateBoardVisible}
        onClose={() => setIsCreateBoardVisible(false)}
      />
      <UpdateBoardContainer
        gameId={gameId}
        board={selectedBoard}
        isVisible={!!selectedBoard}
        onClose={() => setSelectedBoard(null)}
      />
      <div className="pb-4">
        <h2>{name}</h2>
      </div>
      <div className="pb-4">
        <span className="pe-2">
          <Button
            variant="green"
            type="button"
            onClick={() => setIsCreateBoardVisible(true)}
          >
            New Board
          </Button>
        </span>
        <span className="px-2">
          <Button
            variant="blue"
            type="button"
            onClick={() => setIsUpdateGameVisible(true)}
          >
            Edit Game Settings
          </Button>
        </span>
        <span className="ps-2">
          <Button
            variant="blue"
            type="button"
            onClick={() => setIsCompletedVisible(!isCompletedVisible)}
          >
            {isCompletedVisible ? 'Hide ' : 'Show '}
            Completed Boards
          </Button>
        </span>
      </div>
      <Row xs={1} sm={2} md={3}>
        {boardsContent}
      </Row>
    </Container>
  );
};

Game.propTypes = {
  boards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  game: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Game;
