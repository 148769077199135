import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Team from 'components/Team';

const TeamContainer = (props) => {
  const { team, isLoading } = props;
  return <Team team={team} isLoading={isLoading} />;
};

TeamContainer.propTypes = {
  team: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    team,
    loading: { isGetInvitesLoading, isGetUsersLoading },
  } = state;

  return {
    team,
    isLoading: isGetInvitesLoading || isGetUsersLoading,
  };
};

export default connect(mapStateToProps)(TeamContainer);
