import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import AcceptInvite from 'components/AcceptInvite';
import Loading from 'components/Loading';
import { acceptInviteAction, getInviteAction } from 'js/actions/inviteActions';

const AcceptInviteContainer = (props) => {
  const {
    acceptInvite,
    error,
    getInvite,
    invite,
    invite: { id },
    isAcceptInviteLoading,
    isLoading,
  } = props;
  const { inviteId } = useParams();
  const [isLoadStarted, setIsLoadStarted] = useState(false);

  useEffect(() => {
    getInvite({ id: inviteId });
    setIsLoadStarted(true);
  }, []);

  if (id && !isLoading) {
    return (
      <AcceptInvite
        invite={invite}
        isLoading={isAcceptInviteLoading}
        error={error}
        onSubmit={acceptInvite}
      />
    );
  } else if (!isLoading && isLoadStarted) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  } else {
    return <Loading />;
  }
};

AcceptInviteContainer.propTypes = {
  acceptInvite: PropTypes.func.isRequired,
  getInvite: PropTypes.func.isRequired,
  invite: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  isAcceptInviteLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { acceptInviteError },
    loading: { isAcceptInviteLoading, isGetInviteLoading },
    invite,
  } = state;

  return {
    error: acceptInviteError || {},
    invite,
    isLoading: isGetInviteLoading,
    isAcceptInviteLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  acceptInvite: (payload) => dispatch(acceptInviteAction(payload)),
  getInvite: (payload) => dispatch(getInviteAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptInviteContainer);
