import React from 'react';
import PropTypes from 'prop-types';
import { hexToRgba } from 'js/utils';
import './styles.scss';

const AnswerCircle = (props) => {
  const {
    backgroundColor,
    backgroundOpacity,
    letter,
    isCorrect,
    isSigilGame,
  } = props;
  const background = hexToRgba(backgroundColor, backgroundOpacity);

  let className = 'coin-container';
  if (isCorrect) {
    className += ' correct';
  }
  if (isSigilGame) {
    className += ' animate';
  }

  const getLetterCircle = (side) => (
    <div style={{ background }} className={`${side}-circle`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <text
          x="50%"
          y="55%"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {letter}
        </text>
      </svg>
    </div>
  );

  return (
    <div className={className}>
      {getLetterCircle('front')}

      {isSigilGame ? (
        <div style={{ background }} className="back-circle">
          <img src={`files/sigils/${letter}.svg`} alt={letter} />
        </div>
      ) : getLetterCircle('back')}
    </div>
  );
};

AnswerCircle.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  backgroundOpacity: PropTypes.number.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  isSigilGame: PropTypes.bool.isRequired,
  letter: PropTypes.string.isRequired,
};

export default AnswerCircle;
