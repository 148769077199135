import React from 'react';
import Container from 'react-bootstrap/Container';
import './styles.scss';

const NotFound = () => (
  <Container fluid className="not-found-container">
    <div>
      <h1>Game not found</h1>
    </div>
    <div>
      <h3>Uh oh! The page you are trying to access does not exist.</h3>
    </div>
  </Container>
);

export default NotFound;
