import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import Play from 'components/Play';
import { getActiveBoardAction } from 'js/actions/boardActions';

const PlayContainer = (props) => {
  const { board, getActiveBoard, isLoading } = props;
  const { slug } = useParams();
  const [isLoadStarted, setIsLoadStarted] = useState(false);

  useEffect(() => {
    getActiveBoard(slug);
    setIsLoadStarted(true);
  }, []);

  if (board && !isLoading) {
    return <Play board={board} />;
  } else if (!isLoading && isLoadStarted) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  } else {
    return <Loading />;
  }
};

PlayContainer.propTypes = {
  board: PropTypes.shape({}).isRequired,
  getActiveBoard: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    boards: { active },
    loading: { isGetActiveBoardLoading },
  } = state;

  return {
    board: active,
    isLoading: isGetActiveBoardLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getActiveBoard: (payload) => dispatch(getActiveBoardAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayContainer);
