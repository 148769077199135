import React from 'react';
import PropTypes from 'prop-types';
import KeyboardCircle from 'components/KeyboardCircle';
import { keyboardRows } from 'js/constants/boards';
import './styles.scss';

const NO_GUESS_COLOR = '#747877';

const Keyboard = (props) => {
  const {
    primaryColor,
    secondaryColor,
    primaryLetters,
    secondaryLetters,
    incorrectLetters,
    onLetterSelect,
    isDisabled,
  } = props;

  return (
    <div className={`keyboard-container${isDisabled ? ' disabled' : ''}`}>
      {keyboardRows.map((letters, i) => (
        <div key={i} className="keyboard-row-container">
          {letters.map((letter) => {
            let color = NO_GUESS_COLOR;
            let isKeyDisabled = false;

            if (primaryLetters.includes(letter)) {
              color = primaryColor;
            } else if (secondaryLetters.includes(letter)) {
              color = secondaryColor;
            } else if (incorrectLetters.includes(letter)) {
              isKeyDisabled = true;
            }

            return (
              <div key={letter} className="keyboard-circle-container">
                <KeyboardCircle
                  letter={letter}
                  backgroundColor={color}
                  isDisabled={isKeyDisabled}
                  onClick={onLetterSelect}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

Keyboard.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  primaryLetters: PropTypes.arrayOf(PropTypes.string).isRequired,
  secondaryLetters: PropTypes.arrayOf(PropTypes.string).isRequired,
  incorrectLetters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onLetterSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default Keyboard;
