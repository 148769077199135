import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logOutAction } from 'js/actions/userActions';
import Navbar from 'components/Navbar';

const NavbarContainer = ({ isStaff, isLoggedIn, logOut }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsExpanded(false);
  }, [location]);

  const onToggle = () => setIsExpanded(!isExpanded);

  return (
    <Navbar
      isStaff={isStaff}
      isExpanded={isExpanded}
      isLoggedIn={isLoggedIn}
      onLogOut={logOut}
      onToggle={onToggle}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  logOut: (payload) => dispatch(logOutAction(payload)),
});

NavbarContainer.propTypes = {
  isStaff: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(NavbarContainer);
