import React from 'react';
import PropTypes from 'prop-types';

const ExampleOneSvg = ({ color }) => (
  <svg width="231" height="43" viewBox="0 0 231 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42.526" height="42.526" rx="21.263" fill="#404342" />
    <rect x="0.752514" y="0.752514" width="41.021" height="41.021" rx="20.5105" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M26.8923 12.0282H28.7665V30H26.8923V12.0282ZM15.6471 30H13.7473V12.0282H15.6471V30ZM27.0721 21.7073H15.4417V20.0385H27.0721V21.7073Z" fill="white" />
    <rect x="47.1084" width="42.526" height="42.526" rx="21.263" fill="#404342" />
    <rect x="47.8609" y="0.752514" width="41.021" height="41.021" rx="20.5105" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M64.4055 20.0642H73.9049V21.6816H64.4055V20.0642ZM64.6109 28.3569H75.4196V30H62.711V12.0282H75.0345V13.6713H64.6109V28.3569Z" fill="white" />
    <rect x="94.2158" width="42.526" height="42.526" rx="21.263" fill="#404342" />
    <rect x="94.9683" y="0.752514" width="41.021" height="41.021" rx="20.5105" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M110.846 30V12.0282H112.746V28.3569H122.81V30H110.846Z" fill="white" />
    <rect x="141.324" width="42.526" height="42.526" rx="21.263" fill={color} />
    <rect x="142.077" y="0.752514" width="41.021" height="41.021" rx="20.5105" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M157.955 30V12.0282H159.855V28.3569H169.919V30H157.955Z" fill="white" />
    <rect x="188.433" width="42.526" height="42.526" rx="21.263" fill="#404342" />
    <rect x="189.185" y="0.752514" width="41.021" height="41.021" rx="20.5105" stroke="white" strokeOpacity="0.5" strokeWidth="1.50503" />
    <path d="M209.71 30.154C208.358 30.154 207.1 29.9315 205.936 29.4865C204.789 29.0244 203.788 28.3825 202.932 27.561C202.094 26.7223 201.435 25.7552 200.955 24.6598C200.493 23.5473 200.262 22.332 200.262 21.0141C200.262 19.6962 200.493 18.4895 200.955 17.3941C201.435 16.2815 202.094 15.3145 202.932 14.4929C203.788 13.6542 204.789 13.0124 205.936 12.5674C207.083 12.1052 208.341 11.8742 209.71 11.8742C211.062 11.8742 212.312 12.1052 213.459 12.5674C214.605 13.0124 215.598 13.6457 216.437 14.4672C217.293 15.2888 217.952 16.2559 218.414 17.3684C218.893 18.4809 219.133 19.6962 219.133 21.0141C219.133 22.332 218.893 23.5473 218.414 24.6598C217.952 25.7723 217.293 26.7394 216.437 27.561C215.598 28.3825 214.605 29.0244 213.459 29.4865C212.312 29.9315 211.062 30.154 209.71 30.154ZM209.71 28.4596C210.788 28.4596 211.781 28.2798 212.688 27.9204C213.613 27.5439 214.409 27.0218 215.076 26.3543C215.761 25.6697 216.291 24.8823 216.668 23.9923C217.044 23.0851 217.233 22.0924 217.233 21.0141C217.233 19.9358 217.044 18.9516 216.668 18.0616C216.291 17.1544 215.761 16.3671 215.076 15.6996C214.409 15.0149 213.613 14.4929 212.688 14.1335C211.781 13.7569 210.788 13.5686 209.71 13.5686C208.632 13.5686 207.631 13.7569 206.706 14.1335C205.782 14.4929 204.978 15.0149 204.293 15.6996C203.625 16.3671 203.095 17.1544 202.701 18.0616C202.325 18.9516 202.136 19.9358 202.136 21.0141C202.136 22.0753 202.325 23.0595 202.701 23.9666C203.095 24.8738 203.625 25.6697 204.293 26.3543C204.978 27.0218 205.782 27.5439 206.706 27.9204C207.631 28.2798 208.632 28.4596 209.71 28.4596Z" fill="white" />
  </svg>
);

ExampleOneSvg.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ExampleOneSvg;
