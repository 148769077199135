import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import AnswerRow from 'components/AnswerRow';
import GameOver from 'components/GameOver';
import Instructions from 'components/Instructions';
import Keyboard from 'components/Keyboard';
import EmailContainer from 'containers/EmailContainer';
import { keyboardRows } from 'js/constants/boards';
import { words } from 'js/constants/words';
import { logEvent, useMediaQuery } from 'js/utils';
import './styles.scss';

const SIGIL_GAME_SLUG = process.env.REACT_APP_SIGIL_GAME_SLUG;

const Play = (props) => {
  const { board } = props;
  const {
    id: boardId,
    title,
    image,
    answer: lowerCaseAnswer,
    guesses: numberOfGuesses,
    winningTitle,
    winningBody,
    losingTitle,
    losingBody,
    ctaUrl,
    ctaText,
    game: {
      id: gameId,
      name,
      slug,
      primaryColor,
      secondaryColor,
      primaryEmoji,
      secondaryEmoji,
      tertiaryEmoji,
      desktopBackground,
      mobileBackground,
      isEmailRequired,
    } = {},
  } = board || {};

  const logGameEvent = (event, eventProperties = {}) => {
    const properties = {
      game: name,
      gameId,
      boardId,
    };
    logEvent(event, { ...properties, ...eventProperties });
  };

  useEffect(() => {
    logGameEvent('Game board loaded');
  }, []);

  const answer = lowerCaseAnswer.toUpperCase();

  const [guesses, setGuesses] = useState(new Array(numberOfGuesses).fill({
    guess: '',
    isSubmitted: false,
    isInvalid: false,
  }));
  const [gameStatus, setGameStatus] = useState('playing');
  const [isGameOverVisible, setGameOverVisible] = useState(false);
  const [isAddEmailVisible, setIsAddEmailVisible] = useState(isEmailRequired);
  const [isInstructionsVisible, setIsInstructionsVisible] = useState(
    !isEmailRequired,
  );

  const getCurrentIndex = (current) => current.findIndex(({ isSubmitted }) => (
    !isSubmitted
  ));

  const isSubmitEnabled = (current) => {
    const guessNumber = getCurrentIndex(current);

    return (
      guessNumber !== -1
      && current[guessNumber].guess.length === answer.length
    );
  };

  const isGuessValid = (current) => {
    const guessNumber = getCurrentIndex(current);

    return (
      guessNumber !== -1
      && current[guessNumber].guess.length === answer.length
      && words[answer.length].includes(
        current[guessNumber].guess.toLowerCase(),
      )
    );
  };

  const handleLetterSelect = (letter) => {
    setGuesses((oldGuesses) => {
      const guessNumber = getCurrentIndex(oldGuesses);
      let { guess } = oldGuesses[guessNumber];

      if (guess.length < answer.length) {
        guess += letter;
      }

      logGameEvent('Letter typed');

      return [
        ...oldGuesses.slice(0, guessNumber),
        { guess, isSubmitted: false, isInvalid: false },
        ...oldGuesses.slice(guessNumber + 1),
      ];
    });
  };

  const handleDelete = () => {
    setGuesses((oldGuesses) => {
      const guessNumber = getCurrentIndex(oldGuesses);
      let { guess } = oldGuesses[guessNumber];

      guess = guess.substring(0, guess.length - 1);

      return [
        ...oldGuesses.slice(0, guessNumber),
        { guess, isSubmitted: false, isInvalid: false },
        ...oldGuesses.slice(guessNumber + 1),
      ];
    });
  };

  const handleSubmit = () => {
    setGuesses((oldGuesses) => {
      const guessNumber = getCurrentIndex(oldGuesses);
      const { guess } = oldGuesses[guessNumber];

      if (isGuessValid(oldGuesses)) {
        logGameEvent('Guess submitted', { guess });
        return [
          ...oldGuesses.slice(0, guessNumber),
          { guess, isSubmitted: true, isInvalid: false },
          ...oldGuesses.slice(guessNumber + 1),
        ];
      } else if (isSubmitEnabled(oldGuesses)) {
        return [
          ...oldGuesses.slice(0, guessNumber),
          { guess: '', isSubmitted: false, isInvalid: true },
          ...oldGuesses.slice(guessNumber + 1),
        ];
      } else {
        return oldGuesses;
      }
    });
  };

  const handleKeyUp = (e) => {
    if (e.key.match(/^[A-Za-z]{1}$/)) {
      handleLetterSelect(e.key.toUpperCase());
    } else if (e.key === 'Enter') {
      handleSubmit();
    } else if (e.key === 'Backspace') {
      handleDelete();
    }
  };

  const isGameWon = guesses.some(({ guess, isSubmitted }) => (
    isSubmitted && guess === answer
  ));

  const isGameLost = !guesses.some(({ isSubmitted }) => !isSubmitted);

  useEffect(() => {
    if (!(isGameOverVisible || isAddEmailVisible || isInstructionsVisible)) {
      document.addEventListener('keyup', handleKeyUp, true);
    } else {
      document.removeEventListener('keyup', handleKeyUp, true);
    }

    return () => document.removeEventListener('keyup', handleKeyUp, true);
  }, [isGameOverVisible, isAddEmailVisible, isInstructionsVisible]);

  useEffect(() => {
    if (isGameWon) {
      setGameStatus('won');
      logGameEvent('Game won');
    } else if (isGameLost) {
      setGameStatus('lost');
      logGameEvent('Game lost');
    }

    if (isGameWon || isGameLost) {
      setTimeout(() => setGameOverVisible(true), 3000);
    }
  }, [isGameWon, isGameLost]);

  const primaryLetters = [];
  const secondaryLetters = [];

  [...answer].forEach((letter, i) => {
    if (guesses.some(({ guess, isSubmitted }) => (
      isSubmitted && letter === guess[i]
    ))) {
      primaryLetters.push(letter);
    } else if (guesses.some(({ guess, isSubmitted }) => (
      isSubmitted && guess.includes(letter)
    ))) {
      secondaryLetters.push(letter);
    }
  });

  const remainingLetters = keyboardRows.flat().filter((letter) => (
    !(primaryLetters.includes(letter) || secondaryLetters.includes(letter))
  ));

  const incorrectLetters = remainingLetters.filter((letter) => (
    guesses.some(({ guess, isSubmitted }) => (
      isSubmitted && guess.includes(letter)
    ))
  ));

  const { modalHeader, modalDescription } = gameStatus === 'won' ? {
    modalHeader: winningTitle,
    modalDescription: winningBody,
  } : {
    modalHeader: losingTitle,
    modalDescription: losingBody,
  };

  const getShareText = () => {
    let guessString;
    if (gameStatus === 'won') {
      const index = getCurrentIndex(guesses);
      guessString = `Solved in ${index > 0 ? index : numberOfGuesses}`;
    }

    let emojiString = '';
    guesses.forEach(({ guess, isSubmitted }) => {
      if (isSubmitted) {
        const unmatched = {};
        const colors = new Array(answer.length).fill(tertiaryEmoji);

        for (let i = 0; i < answer.length; i++) {
          const letter = answer[i];
          if (letter === guess[i]) {
            colors[i] = primaryEmoji;
          } else {
            unmatched[letter] = letter in unmatched ? unmatched[letter] + 1 : 1;
          }
        }

        for (let i = 0; i < answer.length; i++) {
          const letter = guess[i];
          if (letter !== answer[i] && unmatched[letter]) {
            colors[i] = secondaryEmoji;
            unmatched[letter]--;
          } else if (letter !== answer[i]) {
            colors[i] = tertiaryEmoji;
          }
        }

        emojiString = (
          emojiString
            ? `${emojiString}\n${colors.join('')}`
            : colors.join('')
        );
      }
    });

    return (
      guessString
        ? `${title}\n${guessString}\n\n${emojiString}`
        : `${title}\n\n${emojiString}`
    );
  };

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const getStyles = () => {
    let backgroundImage;
    if (isDesktop && desktopBackground) {
      backgroundImage = desktopBackground;
    } else if (!isDesktop && mobileBackground) {
      backgroundImage = mobileBackground;
    }

    return backgroundImage && { backgroundImage: `url(${backgroundImage})` };
  };

  return (
    <Container fluid style={getStyles()} className="play-container">
      <GameOver
        header={modalHeader}
        description={modalDescription}
        shareText={getShareText()}
        slug={slug}
        ctaUrl={ctaUrl}
        ctaText={ctaText}
        isVisible={isGameOverVisible}
      />
      <EmailContainer
        boardId={boardId}
        gameId={gameId}
        isVisible={isAddEmailVisible}
        onClose={() => {
          setIsAddEmailVisible(false);
          setIsInstructionsVisible(true);
        }}
      />
      <Instructions
        guesses={numberOfGuesses}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        isVisible={isInstructionsVisible}
        onClose={() => setIsInstructionsVisible(false)}
      />
      <div className="play-content-container">
        <div className="play-header-container">
          <img src={image} alt={name} />
        </div>
        <div className="guesses-container">
          {guesses.map(({ guess, isSubmitted, isInvalid }, i) => (
            <AnswerRow
              key={i}
              letters={guess}
              answer={answer}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              isSubmitted={isSubmitted}
              isInvalid={isInvalid}
              isSigilGame={slug === SIGIL_GAME_SLUG}
            />
          ))}
        </div>
        <div className="play-keyboard-container">
          <Keyboard
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            primaryLetters={primaryLetters}
            secondaryLetters={secondaryLetters}
            incorrectLetters={incorrectLetters}
            onLetterSelect={handleLetterSelect}
            isDisabled={gameStatus !== 'playing'}
          />
          <div className="keyboard-buttons-container">
            <div className="pe-2 w-100">
              <button
                type="button"
                onClick={handleDelete}
                className="keyboard-button"
              >
                ERASE
              </button>
            </div>
            <div className="ps-2 w-100">
              <button
                type="button"
                onClick={handleSubmit}
                className="keyboard-button"
                disabled={!isSubmitEnabled(guesses)}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

Play.propTypes = {
  board: PropTypes.shape({}).isRequired,
};

export default Play;
