import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';

const WEB_URL = process.env.REACT_APP_WEB_URL;

const GameOver = (props) => {
  const {
    header,
    description,
    shareText,
    slug,
    ctaUrl,
    ctaText,
    isVisible,
  } = props;

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(`${shareText}\n\n${WEB_URL}/${slug}`);
    setIsCopied(true);
  };

  return (
    <Modal centered show={isVisible}>
      <Container fluid className="game-over-container">
        <div className="pb-3 text-center">
          <h3>{header}</h3>
        </div>
        <div className="pb-3 text-center medium-gray-text">
          {description}
        </div>
        <div className="share-container">
          <div className="share-text">
            {shareText}
          </div>
        </div>
        <div className="pb-1 w-100">
          <Button
            variant="green"
            type="button"
            className="w-100"
            onClick={handleCopy}
          >
            {isCopied ? 'Copied!' : 'Copy and Share'}
          </Button>
        </div>
        <div className="medium-gray-text pb-3">
          This option will copy the board to your clipboard.
        </div>
        <div className="w-100">
          <Button
            variant="blue"
            type="button"
            className="w-100 no-underline"
            href={ctaUrl}
          >
            {ctaText}
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

GameOver.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  shareText: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default GameOver;
