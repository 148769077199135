import boardMiddleware from './boardMiddleware';
import gameMiddleware from './gameMiddleware';
import inviteMiddleware from './inviteMiddleware';
import loggingMiddleware from './loggingMiddleware';
import userMiddleware from './userMiddleware';

const middlewares = [
  loggingMiddleware,
  userMiddleware,
  inviteMiddleware,
  gameMiddleware,
  boardMiddleware,
];

export default middlewares;
