import { combineReducers } from 'redux';
import boardReducer from './boardReducer';
import errorReducer from './errorReducer';
import gameReducer from './gameReducer';
import inviteReducer from './inviteReducer';
import loadingReducer from './loadingReducer';
import teamReducer from './teamReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  boards: boardReducer,
  errors: errorReducer,
  games: gameReducer,
  invite: inviteReducer,
  loading: loadingReducer,
  team: teamReducer,
  user: userReducer,
});

export default rootReducer;
