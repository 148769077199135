import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const GameForm = (props) => {
  const {
    game: {
      name: existingName,
      slug: existingSlug,
      backgroundColor: existingBackgroundColor,
      titleColor: existingTitleColor,
      primaryColor: existingPrimaryColor,
      secondaryColor: existingSecondaryColor,
      primaryEmoji: existingPrimaryEmoji,
      secondaryEmoji: existingSecondaryEmoji,
      tertiaryEmoji: existingTertiaryEmoji,
      desktopBackground: existingDesktopBackground,
      mobileBackground: existingMobileBackground,
    } = {},
    error: {
      detail,
      errors: {
        name: [nameError] = [],
        slug: [slugError] = [],
        background_color: [backgroundColorError] = [],
        title_color: [titleColorError] = [],
        primary_color: [primaryColorError] = [],
        secondary_color: [secondaryColorError] = [],
        primary_emoji: [primaryEmojiError] = [],
        secondary_emoji: [secondaryEmojiError] = [],
        tertiary_emoji: [tertiaryEmojiError] = [],
        desktop_background: [desktopBackgroundError] = [],
        mobile_background: [mobileBackgroundError] = [],
      } = {},
    },
    header,
    isLoading,
    isVisible,
    onClose,
    onSave,
  } = props;

  const [name, setName] = useState(existingName || '');
  const [slug, setSlug] = useState(existingSlug || '');
  const [backgroundColor, setBackgroundColor] = useState(
    existingBackgroundColor || '',
  );
  const [titleColor, setTitleColor] = useState(existingTitleColor || '');
  const [primaryColor, setPrimaryColor] = useState(existingPrimaryColor || '');
  const [secondaryColor, setSecondaryColor] = useState(
    existingSecondaryColor || '',
  );
  const [primaryEmoji, setPrimaryEmoji] = useState(existingPrimaryEmoji || '');
  const [secondaryEmoji, setSecondaryEmoji] = useState(
    existingSecondaryEmoji || '',
  );
  const [tertiaryEmoji, setTertiaryEmoji] = useState(
    existingTertiaryEmoji || '',
  );
  const [desktopBackground, setDesktopBackground] = useState(
    existingDesktopBackground || '',
  );
  const [mobileBackground, setMobileBackground] = useState(
    existingMobileBackground || '',
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      setIsSubmitted(false);

      if (!detail) {
        onClose();
      }
    }
  }, [isLoading]);

  const isButtonDisabled = (isLoading || !(
    name
    && slug
    && primaryColor
    && secondaryColor
    && primaryEmoji
    && secondaryEmoji
    && tertiaryEmoji
  ));

  const shouldRenderFormError = detail && !(
    nameError
    || slugError
    || backgroundColorError
    || titleColorError
    || primaryColorError
    || secondaryColorError
    || primaryEmojiError
    || secondaryEmojiError
    || tertiaryEmojiError
    || desktopBackgroundError
    || mobileBackgroundError
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name,
      slug,
      backgroundColor,
      titleColor,
      primaryColor,
      secondaryColor,
      primaryEmoji,
      secondaryEmoji,
      tertiaryEmoji,
      desktopBackground,
      mobileBackground,
    };

    setIsSubmitted(true);
    onSave(payload);
  };

  return (
    <Modal centered show={isVisible} onHide={onClose}>
      <Container fluid className="game-form-container">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="pb-3 text-center">
            <h3>{header}</h3>
          </div>
          <Form.Group className="form-group" controlId="name">
            <Form.Label className="black-text bold-text">
              Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a name for the game"
              isInvalid={!!nameError}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && (
              <Form.Control.Feedback type="invalid">
                {nameError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="slug">
            <Form.Label className="black-text bold-text">
              Slug
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a unique identifier to go in the url"
              isInvalid={!!slugError}
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            />
            {slugError && (
              <Form.Control.Feedback type="invalid">
                {slugError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="background-color">
                <Form.Label className="black-text bold-text">
                  Background Color
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="#000000"
                  isInvalid={!!backgroundColorError}
                  value={backgroundColor}
                  onChange={(e) => setBackgroundColor(e.target.value)}
                />
                {backgroundColorError && (
                  <Form.Control.Feedback type="invalid">
                    {backgroundColorError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="title-color">
                <Form.Label className="black-text bold-text">
                  Title Color
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="#ffffff"
                  isInvalid={!!titleColorError}
                  value={titleColor}
                  onChange={(e) => setTitleColor(e.target.value)}
                />
                {titleColorError && (
                  <Form.Control.Feedback type="invalid">
                    {titleColorError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="primary-color">
                <Form.Label className="black-text bold-text">
                  Right Letter, Right Place
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="#8b0305"
                  isInvalid={!!primaryColorError}
                  value={primaryColor}
                  onChange={(e) => setPrimaryColor(e.target.value)}
                />
                {primaryColorError && (
                  <Form.Control.Feedback type="invalid">
                    {primaryColorError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="secondary-color">
                <Form.Label className="black-text bold-text">
                  Right Letter, Wrong Place
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="#967841"
                  isInvalid={!!secondaryColorError}
                  value={secondaryColor}
                  onChange={(e) => setSecondaryColor(e.target.value)}
                />
                {secondaryColorError && (
                  <Form.Control.Feedback type="invalid">
                    {secondaryColorError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="primary-emoji">
                <Form.Label className="black-text bold-text">
                  Right Letter Emoji
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter a single emoji character"
                  isInvalid={!!primaryEmojiError}
                  value={primaryEmoji}
                  onChange={(e) => setPrimaryEmoji(e.target.value)}
                />
                {primaryEmojiError && (
                  <Form.Control.Feedback type="invalid">
                    {primaryEmojiError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="secondary-emoji">
                <Form.Label className="black-text bold-text">
                  Wrong Place Emoji
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter a single emoji character"
                  isInvalid={!!secondaryEmojiError}
                  value={secondaryEmoji}
                  onChange={(e) => setSecondaryEmoji(e.target.value)}
                />
                {secondaryEmojiError && (
                  <Form.Control.Feedback type="invalid">
                    {secondaryEmojiError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="tertiary-emoji">
                <Form.Label className="black-text bold-text">
                  Wrong Letter Emoji
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter a single emoji character"
                  isInvalid={!!tertiaryEmojiError}
                  value={tertiaryEmoji}
                  onChange={(e) => setTertiaryEmoji(e.target.value)}
                />
                {tertiaryEmojiError && (
                  <Form.Control.Feedback type="invalid">
                    {tertiaryEmojiError}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group" controlId="desktop-background">
            <Form.Label className="black-text bold-text">
              Desktop Background Image
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Please enter the image url"
              isInvalid={!!desktopBackgroundError}
              value={desktopBackground}
              onChange={(e) => setDesktopBackground(e.target.value)}
            />
            {desktopBackgroundError && (
              <Form.Control.Feedback type="invalid">
                {desktopBackgroundError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="mobile-background">
            <Form.Label className="black-text bold-text">
              Mobile Background Image
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Please enter the image url"
              isInvalid={!!mobileBackgroundError}
              value={mobileBackground}
              onChange={(e) => setMobileBackground(e.target.value)}
            />
            {mobileBackgroundError && (
              <Form.Control.Feedback type="invalid">
                {mobileBackgroundError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="pb-3">
            <Button
              variant="blue"
              type="submit"
              disabled={isButtonDisabled}
              className="full-width"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Save'
              )}
            </Button>
          </div>
          <div>
            <Button
              variant="transparent"
              type="button"
              onClick={onClose}
              className="full-width error-red-text"
            >
              Cancel
            </Button>
          </div>
          {shouldRenderFormError && (
            <div className="invalid-feedback">{detail}</div>
          )}
        </Form>
      </Container>
    </Modal>
  );
};

GameForm.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
    errors: PropTypes.shape({}),
  }).isRequired,
  game: PropTypes.shape({}).isRequired,
  header: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default GameForm;
