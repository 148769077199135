import { createReducer } from './utils';

const initialState = {
  isSignUpLoading: false,
  isLogInLoading: false,
  isGetUserLoading: false,
  isGetUsersLoading: false,
  isChangePasswordLoading: false,
  isGetGamesLoading: false,
  isCreateGameLoading: false,
  isUpdateGameLoading: false,
  isDeleteGameLoading: false,
  isGetBoardsLoading: false,
  isCreateBoardLoading: false,
  isUpdateBoardLoading: false,
  isDeleteBoardLoading: false,
  isGetActiveBoardLoading: false,
  isAddEmailLoading: false,
  isAcceptInviteLoading: false,
  isCreateInviteLoading: false,
  isGetInviteLoading: false,
  isGetInvitesLoading: false,
};

const resetLoading = () => initialState;

const setLoading = (loadingState, { payload }) => ({
  ...loadingState,
  ...payload,
});

const loadingReducer = createReducer(initialState, {
  RESET_LOADING: resetLoading,
  SET_LOADING: setLoading,
});

export default loadingReducer;
