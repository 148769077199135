import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import logo from 'assets/images/logo.svg';
import './styles.scss';

const Navbar = (props) => {
  const {
    isStaff,
    isExpanded,
    isLoggedIn,
    onLogOut,
    onToggle,
  } = props;

  return (
    <BootstrapNavbar
      expand="md"
      expanded={isExpanded}
      onToggle={onToggle}
      className="small-navbar navbar-white"
    >
      <BootstrapNavbar.Brand className="align-self-center">
        <Link to="/staff/games" className="full-height full-width">
          <img src={logo} alt="logo" className="logo" />
        </Link>
      </BootstrapNavbar.Brand>
      {isLoggedIn && (
        <>
          <BootstrapNavbar.Toggle
            aria-controls="basic-navbar-nav"
          />
          <BootstrapNavbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-right">
              <div className="d-md-none mb-3">
                <hr className="nav-divider" />
              </div>
              <div className="nav-link-container">
                <NavLink
                  to="/staff/games"
                  activeClassName="active"
                  data-content="Games"
                >
                  Games
                </NavLink>
              </div>
              {isStaff && (
                <div className="nav-link-container">
                  <NavLink
                    to="/staff/team"
                    activeClassName="active"
                    data-content="Team"
                  >
                    Team
                  </NavLink>
                </div>
              )}
              <div className="nav-link-container">
                <NavLink
                  to="/staff/change-password"
                  activeClassName="active"
                  data-content="Change Password"
                >
                  Change Password
                </NavLink>
              </div>
              <div className="nav-link-container">
                <NavLink
                  to="/log-in"
                  data-content="Log Out"
                  onClick={(e) => {
                    e.preventDefault();
                    onLogOut();
                  }}
                >
                  Log Out
                </NavLink>
              </div>
            </Nav>
          </BootstrapNavbar.Collapse>
        </>
      )}
    </BootstrapNavbar>
  );
};

Navbar.propTypes = {
  isStaff: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  onLogOut: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default Navbar;
