const receiveTeamAction = (payload) => ({ type: 'RECEIVE_TEAM', payload });

const receiveTeammateAction = (payload) => ({
  type: 'RECEIVE_TEAMMATE',
  payload,
});

const removeTeammateAction = (payload) => ({ type: 'REMOVE_TEAMMATE', payload });

const clearTeamAction = () => ({ type: 'CLEAR_TEAM' });

export {
  clearTeamAction,
  receiveTeamAction,
  receiveTeammateAction,
  removeTeammateAction,
};
