import { createPersistedReducer, formatInvite, formatUser } from './utils';

const initialState = {
  users: [],
  invites: [],
};

const receiveTeam = (teamState, { payload }) => {
  const formattedPayload = Object.keys(payload).reduce((acc, key) => {
    acc[key] = payload[key].map(key === 'users' ? formatUser : formatInvite);
    return acc;
  }, {});

  return { ...teamState, ...formattedPayload };
};

const receiveTeammate = (teamState, { payload }) => {
  const { invites: prevInvites, users } = teamState;
  return { invites: [...prevInvites, formatInvite(payload)], users };
};

const removeTeammate = (teamState, { payload: id }) => {
  const { invites, users: prevUsers } = teamState;
  const users = prevUsers.filter((user) => user.id !== id);
  return { invites, users };
};

const clearTeam = () => ({});

const teamReducer = createPersistedReducer(initialState, {
  CLEAR_TEAM: clearTeam,
  RECEIVE_TEAM: receiveTeam,
  RECEIVE_TEAMMATE: receiveTeammate,
  REMOVE_TEAMMATE: removeTeammate,
}, 'team');

export default teamReducer;
