import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LogIn from 'components/LogIn';
import { logInAction } from 'js/actions/userActions';

const LogInContainer = (props) => {
  const {
    logIn,
    error,
    isLoading,
  } = props;

  return <LogIn isLoading={isLoading} error={error} onSubmit={logIn} />;
};

LogInContainer.propTypes = {
  logIn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const {
    errors: { logInError },
    loading: { isLogInLoading },
  } = state;

  return {
    error: logInError || {},
    isLoading: isLogInLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logIn: (payload) => dispatch(logInAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogInContainer);
